//data
import { dataText } from "../../../translate/ru/dataRu";
// types
import { DescribeListType } from "./mockDataType";
import { EquipmentListType } from "./mockDataType";

export const descriptionList: DescribeListType = [
  { id: 1, title: dataText.describes["1"] },
  { id: 2, title: dataText.describes["2"] },
  { id: 3, title: dataText.describes["3"] },
  { id: 4, title: dataText.describes["4"] },
  { id: 5, title: dataText.describes["5"] },
  { id: 6, title: dataText.describes["6"] },
  { id: 7, title: dataText.describes["7"] },
  { id: 8, title: dataText.describes["8"] },
  { id: 9, title: dataText.describes["9"] },
];

export const equipmentList: EquipmentListType = [
  { id: 1, title: dataText.equipment["1"] },
  { id: 2, title: dataText.equipment["2"] },
  { id: 3, title: dataText.equipment["3"] },
  { id: 4, title: dataText.equipment["4"] },
];

export const listDescriptionCorse = [
  { id: 1, title: "1. Схема укладки легких стрелок за 40 минут 🪶" },
  {
    id: 2,
    title:
      "2. Урок по колористике и разбор пигментов по цветотипу клиента- без использования черного",
  },
  {
    id: 3,
    title: "3. Анатомические особенности при построении эскиза",
  },
];
export const listDescriptionNewCorse = [
  { id: 1, title: "1. Четкая и понятная схема " },
  {
    id: 2,
    title:
      "2. Максимально простая и продуктивная",
  },
  {
    id: 3,
    title: "3. Шикарный результат",
  },
];
