import React from "react";
import css from "./BlockNewCourse.module.css"
import { dataText } from "../../../translate/ru/dataRu";
import {  listDescriptionNewCorse } from "../../molecules/list/mockData";
import { PurchaseBtn } from "../../molecules/PurchaseBtn/PurchaseBtn";
import imageNewCourse from "../../../assets/img/imageNewCourse.jpg"


export const BlockNewCourse = () => {
    return (
        <>
          <h2
            className={`${css.title} text-xl p-3 sm:text-4xl md:text-5xl text-center md:p-16`}
          >
            {dataText.smallTitleCourse}
          </h2>
    
          <div className={css.blockCorse}>
            <div className={css.blockInfo}>
              <div className={css.blockMaster}>
                <div className={css.wrapperImgMaster}>
                  <img src={imageNewCourse} alt="master" className={css.imgMaster} />
                  
                </div>
                <ul className={css.listCourse}>
                  <p className="overflow-hidden text-base m-1.5 sm:text-2xl 2xl:text-3xl md:m-4">
                    ✅ Моя техника создания объемного  прокраса! Которую не найти на просторах интернета 🤫
                  </p>
                  <hr />
                  {listDescriptionNewCorse.map((i) => (
                    <li
                      key={i.id}
                      className="overflow-hidden text-base m-1.5 sm:text-2xl 2xl:text-3xl md:m-4"
                    >
                      {i.title}
                    </li>
                  ))}
    
                  <p
                    className={
                      " text-xl p-4 sm:text-3xl md:text-4xl text-center md:p-12 lg:py-8 "
                    }
                  >
                    Стоимость: 1000 BYN
                  </p>
                  <PurchaseBtn href={"https://w-p.by/mhQz"} />
                </ul>
              </div>
            </div>
    
            <div className={css.blockImg}></div>
          </div>
        </>
      );
};